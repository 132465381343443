export default Object.freeze({
    title: {
        en: 'Magic Ray',
        ru: 'Magic Ray',
    },
    chapter0Text0: {
        en: 'ABOUT MAGIC RAY LLC',
        ru: 'О КОМПАНИИ',
    },
    chapter0Text1: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Company “MAGIC RAY” is specialized in power scaling of blue laser diodes and production, based on them, high power emitters. Emitters can be used for laser active medium pumping based on fluorides doped with various rare-earth ions ( Dy, Tb, Pr).',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Фирма «MAGIC RAY» специализируется на масштабировании мощности лазерных диодов синего диапазона и создании на их базе излучателей высокой мощности. Излучатели могут применяться для накачки активных сред на основе фторидов легированных различными ионами редкоземельных элементов ( Dy, Tb, Pr).',
    },
    chapter1Text0: {
        en: 'PRODUCTS AND RESEARCH',
        ru: 'ПРОДУКЦИЯ И ИССЛЕДОВАНИЯ',
    },
    chapter1Text1: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Our specialists have developed and designed laser devices SL-2 for medical purposes. Laser devices of 40 watt and 450nm wavelength (blue diode laser system) intended to be used for benign prostatic hyperplasia treatment (vaporization). In 2016-2017 our laser devices passed ISO certification procedures (certificate of compliance and type certificate were obtained). The laser devices comply with the requirements of EN 60601-1: 2006, IEC 60601-1-1-2011.',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Фирмой были изготовлены лазерные установки SL-2 для медицинских целей. Приборы мощностью 40 Вт излучением 450 нм предназначены для вапоризации доброкачественной гиперплазии предстательной железы в урологии. Пройдены в 2016-2017 г.г. процедуры сертификации по требованиям ISO (были получены сертификат соответствия и сертификат типа на изготовленные изделия). Приборы соответствуют требованиям EN 60601-1: 2006, ГОСТ IEC 60601-1-1-2011.',
    },
    chapter1Text2: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;The main parameters of the laser system are shown in the table below.',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Основные параметры системы приведены в таблице ниже.',
    },
    chapter1Text3: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;At the request of the Kazan University (Russian Federation), a pump laser of 443.8nm of vertical polarization with a power of 9 W was manufactured for a solid-state laser based on a Pr:YLF crystal.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;\tNowadays a 574 nm yellow laser based on Dy:Tb:YLF is being manufactured using an proprietory developed laser emitter with a wavelength of 450.1 nm and an output power of about 8 watts as a pump source.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;The new generation of blue laser diodes was developed for micro and macro material processing of copper and its alloys as well as for noble metals. Several high reflective metals have a limited absorption in the IR and the green wavelength. In this spectral width the blue wavelength has significant advantages.'
        ,
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;По заказу Казанского федерального университета РФ был изготовлен лазер накачки 443,8 нм вертикальной поляризации мощностью 9 Вт для твердотельного лазера на базе кристалла Pr:YLF.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;В настоящее время изготавливается лазер желтого диапазона 574 нм на основе Dy:Tb:YLF с применением в качестве источника накачки лазерного излучателя собственной разработки и изготовления с длиной волны 450,1 нм выходной мощностью около 8 Вт.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;Новое поколение диодов синих лазеров было разработано для обработки микро- и макроматериалов меди и ее сплавов, а также для благородных металлов. Некоторые металлы с высокой отражательной способностью имеют ограниченное поглощение в ИК-диапазоне и на длине волны зеленого цвета. При этой ширине спектра синяя длина волны имеет существенные преимущества.'
        ,
    },
    chapter1Text4: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;For all welding types a spatter-free process is characterizing. This is not only important for the typical welding but also for the additive welding and drilling with a power of 150 W and more.',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Для всех типов сварки характерен процесс без брызг. Это важно не только для обычной сварки, но и для аддитивной (добавочной) сварки и сверления мощностью 150 Вт и более.',
    },
    chapter1Text5: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Emission of the indicated blue wavelength has a number of special characteristics that have an obvious advantage over green, yellow, red, and other spectrum, which allows to solve problems that cannot be fulfilled by other lasers.' +
            '<br/><br/>' + '&nbsp;&nbsp;&nbsp;&nbsp;Advantages of High Power Blue Lasers (compared with an IR laser).' +
            '<br/><br/>' + '&nbsp;&nbsp;&nbsp;&nbsp;The applied devices of blue high power lasers will major transform the metal cutting and welding as well as the additive manufacturing (3D printing).' +
            '<br/><br/><ul>' +
            '<li>' + 'significant higher absorption -> the energy transfer of blue lasers is 2 to 20 times higher for most materials.' +
            '</li><li>' + 'blue lasers allow a 5 to 20 times faster material processing speed -> higher performance.' +
            '</li><li>' +'blue lasers have a 5 times smaller spot -> allows higher precision, higher resolution and better production quality (i.e. in 3D printing).' +
            '</li><li>' +'unique spatter-free welding performance -> ensures a better production quality.' +
            '</li></ul>' +'&nbsp;&nbsp;&nbsp;&nbsp;It seems to be interesting for us the experience of using the 450 nm blue diode lasers in solving technical problems of 3D printing.',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Излучение указанной длины волны обладает рядом особых характеристик, выгодно отличающих его от зеленого, желтого, красного и др. спектров, что позволяет решать задачи, невыполнимые другими лазерами.' +
            '<br/><br/>' + '&nbsp;&nbsp;&nbsp;&nbsp;Преимущества Синего Лазера высокой мощности (в сравнении с лазером инфракрасного диапазона (IR laser). ' +
            '<br/><br/>' + '&nbsp;&nbsp;&nbsp;&nbsp;Применяемая платформа синих лазеров высокой мощности станет важнейшим преобразованием для резки и сварки металла, а также для технологии послойной печати (3D-печать).' +
            '<br/><br/><ul>' +
            '<li>' + 'значительно более высокое поглощение -> для большинства материалов передача энергии синими лазерами в 2-20 раз выше.' +
            '</li><li>' + 'синие лазеры позволяют в 5-20 раз быстрее обрабатывать материалы -> более высокая производительность.' +
            '</li><li>' +'лазеры синего цвета имеют в 5 раз меньшую точку -> обеспечивают более высокую точность, более высокое разрешение и лучшее качество производства (т. е. в 3D-печати).' +
            '</li><li>' +'уникальные характеристики сварки без брызг -> обеспечивает лучшее качество производства.' +
            '</li></ul>' +'&nbsp;&nbsp;&nbsp;&nbsp;Нам представляется интересным опыт применения 450 нм лазеров в решении технических задач 3D-печати. ',

    },
    chapter1Text6: {
        en: 'Anamorphic prism pairs for beam shaping/correction',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Анаморфные призменные пары для формирования/коррекции луча',
    },
    chapter1Text7: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Anamorphic prisms are used to expand one beam axis while keeping the other unchanged.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;High power laser diode like 445nm - 470nm have a high divergent axis. In far field it gives an asymmetrical beam profile and excludes these laser diodes from many applications like show lasers etc.',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Анаморфные призмы используются для расширения одной оси луча, оставляя другую неизменной.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;Мощные лазерные диоды (445 нм - 470 нм)  имеют большую расходящуюся ось. В дальнем поле он дает асимметричный профиль луча и исключает эти лазерные диоды из многих применений, таких как лазерное световое шоу и т.д.'
        ,
    },
    chapter1Text8: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Using our anamorphic prisms allows to reduce the far field beam divergence dramatically (stock available more than 50 pairs).',
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Использование наших анаморфных призм позволяет значительно уменьшить расходимость пучка в дальнем поле (есть в наличии более 50 пар).',
    },

    chapter2Text0: {
        en: 'LINE OF COOPERATION',
        ru: 'НАПРАВЛЕНИЯ СОТРУДНИЧЕСТВА',
    },
    chapter2Text1: {
        en: '&nbsp;&nbsp;&nbsp;&nbsp;Company “MAGIC RAY” specializes in the manufacture of a short-wavelength lasers of 400-520 nm and is ready to consider various types of cooperation, in particular, we could manufacture the customised laser of 450 nm wavelength for materials processing with a spot diameter in focus of 70-200 μm with a optical front end focus distance up to 100 mm at emission power up to 200W. Such a laser can be used for cutting and soldering of metals - gold, copper, etc., hard-to-process by lasers of other wavelengths (520-1080 nm), as well as solving other process tasks. The guaranteed service life of our product is approximately 70-80% of the service lifetime of the diodes while remaining close to the initial operational and technical characteristics.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;We offer cooperation in the field of power scaling of blue laser diodes and production, based on them, high power emitters, as well as emitters for pumping of laser active medium based on fluorides doped with various rare-earth ions ( Dy, Tb, Pr).'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;Our company is open for any forms of cooperation and we believe that together we can solve any tasks and overcome any challenges in the field of blue lasers application.'
        ,
        ru: '&nbsp;&nbsp;&nbsp;&nbsp;Фирма “MAGIC RAY” специализируется на изготовлении лазеров коротковолновых диапазонов 400-520 нм и готова рассмотреть различные виды сотрудничества, в частности, мы могли бы изготовить под заказ лазер с излучением 450 нм для обработки материалов с диаметром пятна в фокусе 70-200 микрон, с фокусным расстоянием объектива до 100 мм при мощности излучения до 200 ватт. Такой лазер может быть использован для резки, пайки металлов, труднообрабатываемых лазерами других длин волн (520-1080 нм) - золото, медь и т. д., а также решения других технологических задач. Гарантированный срок службы нашего изделия составляет примерно 70-80% срока службы диодов при сохранении близких к начальным эксплуатационных и технических параметров.'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;Мы предлагаем сотрудничество в области масштабирования мощности лазерных диодов синего диапазона и создания на их основе излучателей высокой мощности, а также излучателей для накачки активных сред на основе фторидов, легированных ионами редкоземельных элементов (Dy, Tb, Pr).'
            + '<br/><br/>'
            + '&nbsp;&nbsp;&nbsp;&nbsp;Наша фирма открыта к различным формам сотрудничества и мы верим, что вместе сможем решить задачи разной степени сложности в области применения лазеров синего диапазона.'
        ,
    },
    contactsTitle: {
        en: 'CONTACT US',
        ru: 'КОНТАКТЫ',
    },
    contactsText: {
        en: 'LIMITED LIABILITY COMPANY МAGIC RAY (МAGIC RAY LLC)',
        ru: 'LIMITED LIABILITY COMPANY МAGIC RAY (МAGIC RAY LLC)',
    },
    contactsTextAddress: {
        en: '04073, Kyiv, Obolonskyi district, Stepan Bandera ave, 16-D ',
        ru: '04073, г. Киев, Оболонский район, ПРОСПЕКТ Степана Бандеры, дом 16-Д',
    }

});
