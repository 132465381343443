import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img3_1 from './assets/img3_1.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import './App.css';

import locale from './locale'
import styles from './styles'
import logo from './assets/logo_medium.png';

class Pricing extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            lang: 'en'
        }
    }

    componentDidMount() {
        this.setState({lang: (new URL(window.location.href)).searchParams.get("lang") || 'en'});
    }

    getTitle = (key) => (
        <Typography
            variant="h4"
            align="center"
            component="h4"
            dangerouslySetInnerHTML={{__html: this.getText(key)}}
        />
    );

    getParagraph = (key, component = 'p', align = 'left') => (
        <Typography
            variant="h6"
            align={align}
            color="textSecondary"
            component={component}
            dangerouslySetInnerHTML={{__html: this.getText(key)}}
        />
    );

    getText = (key) => (locale[key]) ? locale[key][this.state.lang] : '-';

    render() {
        const {classes} = this.props;
        const {getText, getTitle, getParagraph} = this;

        return (
                <div className="main-container">
                    <AppBar>
                        <Toolbar >
                            <Button color="inherit" href="?lang=en">EN</Button>
                            <Button color="inherit" href="?lang=ru">RU</Button>
                            {isBrowser &&
                                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                    <AnchorLink offset='80' className='ch' href='#ch0'>{getText('chapter0Text0')}</AnchorLink>
                                    <AnchorLink offset='80' className='ch' href='#ch1'>{getText('chapter1Text0')}</AnchorLink>
                                    <AnchorLink offset='80' className='ch' href='#ch2'>{getText('chapter2Text0')}</AnchorLink>
                                    <AnchorLink offset='80' className='ch' href='#ch3'>{getText('contactsTitle')}</AnchorLink>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fake-container'/>
                    <div >
                        <div className={classes.heroContent}>
                            <img src={logo}/>
                        </div>
                    </div>
                    <Paper elevation={1} className="paper" id='ch0'>
                        {getTitle('chapter0Text0')}
                        {getParagraph('chapter0Text1')}
                    </Paper>
                    <Paper elevation={1} className="paper" id='ch1'>
                        {getTitle('chapter1Text0')}
                        {getParagraph('chapter1Text1')}
                        <div style={{display: 'flex', padding: '30px'}}>
                            <div> <img src={img1}/> </div>
                        </div>
                        {getParagraph('chapter1Text2')}
                        <div style={{display: 'flex', padding: '10px'}}>
                            <div> <img src={img3_1}/> </div>
                        </div>
                        {getParagraph('chapter1Text3')}
                        <div style={{display: 'flex', padding: '10px'}}>
                            <div> <img src={img4}/> </div>
                        </div>
                        {getParagraph('chapter1Text4', 'i')}
                        {getParagraph('chapter1Text5')}
                        {getParagraph('chapter1Text6', 'b', 'center')}
                        {getParagraph('chapter1Text7')}
                        <div style={{display: 'flex', padding: '30px'}}>
                            <div> <img src={img5}/> </div>
                            <div> <img src={img6}/> </div>
                        </div>
                        {getParagraph('chapter1Text8')}

                    </Paper>
                    <Paper elevation={1} className="paper" id='ch2'>
                        {getTitle('chapter2Text0')}
                        {getParagraph('chapter2Text1')}
                    </Paper>

                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar className='footer' id='ch3'>
                            <Grid container spacing={24}>
                                <Grid item sm={6} xs={12} className='footer-item'>
                                    {getText('contactsTitle')}:<br/>
                                    {getParagraph('contactsText')}
                                </Grid>
                                <Grid item sm={6} xs={12} style={{fontSize: '16px'}}>
                                    <div className='footer-item'  style={{display: 'flex'}}>
                                        <EmailIcon style={{ fontSize: 14 }}/>&nbsp;&nbsp;:&nbsp;&nbsp;<a href='mailto:mr@magicray.biz'>mr@magicray.biz</a> <br/>
                                    </div>
                                    <div className='footer-item' style={{display: 'flex'}}>
                                        <HomeIcon style={{ fontSize: 18 }}/>&nbsp;:&nbsp;&nbsp;{getParagraph('contactsTextAddress', )}
                                    </div>
                                    <PhoneIcon style={{ fontSize: 14 }}/>&nbsp;&nbsp;:&nbsp;&nbsp;+380632195939 (Viber, WhatsApp, Telegram)
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </div>

        );
    }
}

export default withStyles(styles)(Pricing);
